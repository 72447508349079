import {ComplianceItem, Permit, PropertyTax} from '@common/typing';

export class ParseHelper {
    static fromPermitToComplianceItem(permit: Permit): ComplianceItem {
        return {...permit, attributes: {...permit.attributes, type: permit.attributes.permit_type, number: permit.attributes.permit_number}};
    }

    static fromPropertyTaxToComplianceItem(propertyTax: PropertyTax): ComplianceItem {
        return {
            ...propertyTax,
            attributes: {...propertyTax.attributes, type: propertyTax.attributes.tax_type, number: propertyTax.attributes.tax_number},
        };
    }

    static fromComplianceItemToPermit(item: ComplianceItem): Permit {
        return {...item, attributes: {...item.attributes, permit_type: item.attributes.type, permit_number: item.attributes.number}};
    }

    static fromComplianceItemToPropertyTax(item: ComplianceItem): PropertyTax {
        return {
            ...item,
            attributes: {...item.attributes, tax_type: item.attributes.type, tax_number: item.attributes.number},
        };
    }
}
