import React, {useRef} from 'react';
import styles from '../ComplianceApp.module.scss';
import {ComplianceItem, Type} from '@common/typing';
import _ from 'lodash';
import {Button} from '@common/components';
import {ValidationForms} from '@common/utils';

interface ItemBoxProps {
    item: ComplianceItem;
    setItem: any;
    removePermit: any;
    readOnly?: boolean;
    types: Array<Type>;
    type: 'permit' | 'property_tax';
    displayedOnVacasa: boolean;
}

export const ItemBox: React.FunctionComponent<ItemBoxProps> = (props) => {
    // props
    const {item, setItem, removePermit, readOnly, types, type, displayedOnVacasa} = props;

    let labelType, labelId;
    switch (type) {
        case 'permit':
            labelType = 'Permit Type';
            labelId = 'Permit ID';
            break;

        case 'property_tax':
            labelType = 'Property Tax ID Type';
            labelId = 'Property Tax ID';
            break;

        default:
            labelType = '';
            labelId = '';
            break;
    }

    //references
    const renewalDateInput = useRef(null);

    const handlePropertyChange = (e: any) => {
        const target = e.target as HTMLInputElement;
        let inputName = target.name.split('-')[0]; //cut some inputs with index in the name
        let previousItem = _.cloneDeep(item);

        let value: any = target.type === 'checkbox' ? target.checked : target.value;

        switch (inputName) {
            case 'noRenewalDate':
                renewalDateInput.current.disabled = value;
                inputName = renewalDateInput.current.name;
                value = value ? null : ' ';
                break;

            case 'type':
                value = {id: value, name: ''};
                break;

            case 'number':
                value = ValidationForms.textAndCharacterSpecialPass(value);
                break;

            case 'renewal_date':
            case 'effective_date':
                if (value.length > 10)
                    value = value.slice(0,10);
                break;

            default:
                break;
        }

        const updatedItem = {
            ...previousItem,
            attributes: {...previousItem.attributes, [inputName]: value},
        };

        setItem(updatedItem);
    };

    return (
        <div className={styles.permitItem}>
            <div className="form-row">
                <div className="col-md-2">
                    <div className="form-group">
                        <label>{labelType}</label>
                        <select
                            className="form-control"
                            placeholder="Select"
                            onChange={handlePropertyChange}
                            value={item.attributes?.type?.id || ''}
                            disabled={readOnly}
                            id={`type_${item.index}_${type}`}
                            name="type"
                        >
                            <option value="">--</option>
                            {types.map((type, index) => {
                                return (
                                    <option key={index} value={type.id}>
                                        {type.attributes.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className={'form-group'}>
                        <label>{labelId}</label>
                        <input
                            className="form-control"
                            id={`number_${item.index}_${type}`}
                            name="number"
                            type="text"
                            placeholder=""
                            onChange={handlePropertyChange}
                            value={item.attributes?.number || ''}
                            readOnly={readOnly}
                            maxLength={250}
                        />
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`display_${item.index}_${type}`}
                                name="display"
                                onChange={handlePropertyChange}
                                checked={item.attributes?.display || false}
                                disabled={readOnly}
                            />
                            <label className="form-check-label" htmlFor={`display_${item.index}_${type}`}>
                                Display ID Public
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>Description</label>
                        <input
                            className="form-control"
                            id={`description_${item.index}_${type}`}
                            name="description"
                            type="text"
                            placeholder="Short description"
                            value={item.attributes?.description || ''}
                            onChange={handlePropertyChange}
                            readOnly={readOnly}
                            maxLength={30}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>Effective Date</label>
                        <input
                            className="form-control"
                            id={`effective_date_${item.index}_${type}`}
                            name="effective_date"
                            type="date"
                            placeholder="mm/dd/yyyy"
                            onChange={handlePropertyChange}
                            value={item.attributes.effective_date || ''}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>Renewal Date</label>
                        <input
                            className="form-control"
                            id={`renewal_date_${item.index}_${type}`}
                            name="renewal_date"
                            ref={renewalDateInput}
                            type="date"
                            placeholder="mm/dd/yyyy"
                            onChange={handlePropertyChange}
                            value={item.attributes.renewal_date || ''}
                            readOnly={readOnly || item.attributes?.renewal_date === null}
                        />
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`noRenewalDate_${item.index}_${type}`}
                                name="noRenewalDate"
                                onChange={handlePropertyChange}
                                checked={item.attributes?.renewal_date === null}
                                disabled={readOnly}
                            />
                            <label className="form-check-label" htmlFor={`noRenewalDate_${item.index}_${type}`}>
                                No Renewal Date
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`col-md-2 ${styles.textAlignRight}`}>
                    <Button className="btn btn-link right" type="button" onClick={() => removePermit(item.index)} disabled={readOnly} variant="link">
                        Remove
                    </Button>
                    {displayedOnVacasa ? (
                        <label className="badge badge-success">Displayed on Vacasa.com</label>
                    ) : (
                        <label className="badge badge-warning">Not displayed on Vacasa.com</label>
                    )}
                </div>
            </div>
            <div className="form-row">
                <div className={`col-md-2 ${styles.textAlignLeft}`}>
                    <p className={styles.auditItems}><b>Created at:</b> {item.attributes?.created_at || '-'}</p>
                </div>
                <div className={`col-md-2 ${styles.textAlignLeft}`}>
                    <p className={styles.auditItems}><b>Created by:</b> {item.attributes?.created_by || '-'}</p>
                </div>
                <div className={`col-md-2 ${styles.textAlignLeft}`}>
                    <p className={styles.auditItems}><b>Modified at:</b> {item.attributes?.modified_at || '-'}</p>
                </div>
                <div className={`col-md-2 ${styles.textAlignLeft}`}>
                    <p className={styles.auditItems}><b>Modified by:</b> {item.attributes?.modified_by || '-'}</p>
                </div>
            </div>
        </div>
    );
};
