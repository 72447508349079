import React, {useState} from 'react';
import {ItemBox} from './itemBox/ItemBox';
import {Permit, PermitType, PropertyTax, PropertyTaxType} from '@common/typing';
import {AlertMessage, Button} from '@common/components';
import {ValidationForms} from '@common/utils';
import styles from './ComplianceApp.module.scss';

interface ComplianceAppRenderProps {
    loadingApp: boolean;
    loading: boolean;
    permits: Array<Permit>;
    updatePermit: Function;
    removePermit: Function;
    newPermit: Function;
    savePermits: Function;
    propertyTaxes: Array<PropertyTax>;
    updatePropertyTax: Function;
    removePropertyTax: Function;
    newPropertyTax: Function;
    savePropertyTaxes: Function;
    permitTypes: Array<PermitType>;
    propertyTaxTypes: Array<PropertyTaxType>;
    disableBtnSavePermits: boolean;
    minBookingAge: number;
    saveMinBookingAge: Function;
    setMinBookingAge: Function;
    complianceNotes: string;
    saveComplianceNotes: Function;
    setComplianceNotes: Function;
}

export const ComplianceAppRender: React.FunctionComponent<ComplianceAppRenderProps> = (props) => {
    const {
        loadingApp,
        loading,
        permits,
        updatePermit,
        removePermit,
        newPermit,
        savePermits,
        propertyTaxes,
        updatePropertyTax,
        removePropertyTax,
        newPropertyTax,
        savePropertyTaxes,
        permitTypes,
        propertyTaxTypes,
        disableBtnSavePermits,
        minBookingAge,
        saveMinBookingAge,
        setMinBookingAge,
        saveComplianceNotes,
        complianceNotes,
        setComplianceNotes
    } = props;

    //const [messageError, setMessageError] = useState<{message: string; item: 'permit' | 'property_tax' | 'min_age_requirement'}>(null);
    const [message, setMessage] = useState<{
        msg: string;
        type: 'error' | 'success';
        item: 'permit' | 'property_tax' | 'min_age_requirement' | 'compliance_notes';
        ran: number;
    }>({ran: 0, msg: null, type: null, item: null});

    const handleMinBookingAge = (e) => {
        const value = ValidationForms.onlyNumbersPass(e.target.value);
        setMinBookingAge(value);
    };

    const handleComplianceNotes = (e) => {
        setComplianceNotes(e.target.value);
    };

    const handleButtonSavePermits = () => {
        if (!isValidForm('permit')) {
            return;
        }

        Promise.resolve(savePermits()).then((response) => {
            if (!response) {
                setMessage({msg: 'There was an error saving the data', item: 'permit', type: 'error', ran: message.ran + 1});
            } else {
                setMessage({msg: 'Data Succesfully Saved', item: 'permit', type: 'success', ran: message.ran + 1});
            }
        });
    };

    const handleButtonSavePropertyTax = () => {
        if (!isValidForm('property_tax')) {
            return;
        }

        Promise.resolve(savePropertyTaxes()).then((response) => {
            if (!response) {
                setMessage({msg: 'There was an error saving the data', item: 'property_tax', type: 'error', ran: message.ran + 1});
            } else {
                setMessage({msg: 'Data Succesfully Saved', item: 'property_tax', type: 'success', ran: message.ran + 1});
            }
        });
    };

    const handleButtonMinAgeRequirement = () => {
        Promise.resolve(saveMinBookingAge()).then((response) => {
            if (!response) {
                setMessage({msg: 'There was an error saving the data', item: 'min_age_requirement', type: 'error', ran: message.ran + 1});
            } else {
                setMessage({msg: 'Data Succesfully Saved', item: 'min_age_requirement', type: 'success', ran: message.ran + 1});
            }
        });
    };

    const handleButtonComplianceNotes = () => {
        Promise.resolve(saveComplianceNotes()).then((response) => {
            if (!response) {
                setMessage({msg: 'There was an error saving the data', item: 'compliance_notes', type: 'error', ran: message.ran + 1});
            } else {
                setMessage({msg: 'Data Succesfully Saved', item: 'compliance_notes', type: 'success', ran: message.ran + 1});
            }
        });
    };

    const isValidForm = (type: 'permit' | 'property_tax'): boolean => {
        const errors = getFormErrors(type);
        if (errors.length > 0) {
            console.debug('error', errors[0].message);
            setMessage({msg: errors[0].message, item: type, type: 'error', ran: message.ran + 1});

            if (errors[0].inputId) {
                const input = document.getElementById(errors[0].inputId);
                input.focus();
            }

            return false;
        }

        return true;
    };

    const compareDates = (startDate: string, endDate: string): number => {
        const startFormatDate = startDate.replace(new RegExp('-', 'g'), '');
        const endFormatDate = endDate.replace(new RegExp('-', 'g'), '');

        return +endFormatDate - +startFormatDate;
    };

    const getFormErrors = (type: 'permit' | 'property_tax'): Array<{message: string; inputId: string}> => {
        const errors: Array<{message: string; inputId: string}> = [];

        const items: Array<any> = type === 'permit' ? permits : propertyTaxes;
        const alias = type === 'permit' ? 'Permit ID' : 'Property Tax ID';

        items
            .filter((item) => item.deleted !== true)
            .forEach((item) => {
                if (!item.attributes.unit_id) errors.push({message: 'unit not found', inputId: null});

                if (!item.attributes.type?.id) errors.push({message: 'you must select a Type', inputId: `type_${item.index}_${type}`});

                if (!item.attributes.number) errors.push({message: `you must enter a ${alias}`, inputId: `number_${item.index}_${type}`});

                if (!item.attributes.description && +item.attributes.type?.id === 1)
                    errors.push({message: 'you must enter a Description', inputId: `description_${item.index}_${type}`});

                if (!item.attributes.renewal_date && item.attributes.has_renewal_date === true)
                    errors.push({
                        message: 'you must enter a Renewal Date, or select No Renewal Date',
                        inputId: `renewal_date_${item.index}_${type}`,
                    });

                if (
                    item.attributes.effective_date &&
                    item.attributes.renewal_date &&
                    compareDates(item.attributes.effective_date, item.attributes.renewal_date) <= 0
                ) {
                    errors.push({
                        message: 'renewal date must have a date greater than effective date',
                        inputId: `renewal_date_${item.index}_${type}`,
                    });
                }
            });

        return errors;
    };

    return (
        <div>
            <div className="container-fluid">
                {loadingApp ? (
                    <div className="row">
                        <div className={`col ${styles.centerContent}`}>
                            <h6>Loading data...</h6>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col">
                            <form>
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <h5>Permits</h5>
                                        </div>
                                        <div className="permitItems">
                                            {permits
                                                .filter((permit) => permit.deleted !== true)
                                                .map((permit, index) => {
                                                    return (
                                                        <ItemBox
                                                            displayedOnVacasa={permit.attributes.display || false}
                                                            type="permit"
                                                            key={index}
                                                            item={permit}
                                                            setItem={updatePermit}
                                                            removePermit={removePermit}
                                                            readOnly={false}
                                                            types={permitTypes}
                                                        />
                                                    );
                                                })}
                                        </div>
                                        <div className="permit-footer">
                                            <Button
                                                className="right"
                                                type="button"
                                                variant="primary"
                                                color="white"
                                                disabled={loading || disableBtnSavePermits}
                                                onClick={handleButtonSavePermits}
                                            >
                                                Save Permits
                                            </Button>

                                            <Button
                                                type="button"
                                                className="btn btn-link right"
                                                variant="link"
                                                disabled={permits.filter((permit) => permit.deleted !== true).length >= 5 ? true : false}
                                                onClick={newPermit}
                                            >
                                                + Add Permit
                                            </Button>
                                            {message && message.item === 'permit' && message.msg.length > 0 ? (
                                                <div className={styles.alertBox}>
                                                    <AlertMessage
                                                        message={message.msg}
                                                        type={message.type === 'error' ? 'danger' : 'success'}
                                                        milliseconds={3000}
                                                        key={message.ran}
                                                    />
                                                </div>
                                            ) : (
                                                <span></span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <h5>Property Tax ID</h5>
                                        </div>
                                        <div className="permitItems">
                                            {propertyTaxes
                                                .filter((propertyTax) => propertyTax.deleted !== true)
                                                .map((propertyTax, index) => {
                                                    return (
                                                        <ItemBox
                                                            displayedOnVacasa={propertyTax.attributes.display || false}
                                                            type="property_tax"
                                                            key={index}
                                                            item={propertyTax}
                                                            setItem={updatePropertyTax}
                                                            removePermit={removePropertyTax}
                                                            readOnly={false}
                                                            types={propertyTaxTypes}
                                                        />
                                                    );
                                                })}
                                        </div>
                                        <div className="permit-footer">
                                            <Button
                                                className="right"
                                                type="button"
                                                variant="primary"
                                                color="white"
                                                disabled={loading || disableBtnSavePermits}
                                                onClick={handleButtonSavePropertyTax}
                                            >
                                                Save Property Tax ID
                                            </Button>

                                            <Button
                                                type="button"
                                                className="btn btn-link right"
                                                variant="link"
                                                disabled={propertyTaxes.filter((tax) => tax.deleted !== true).length >= 3 ? true : false}
                                                onClick={newPropertyTax}
                                            >
                                                + Add Property Tax ID
                                            </Button>
                                            {message && message.item === 'property_tax' && message.msg.length > 0 ? (
                                                <div className={styles.alertBox}>
                                                    <AlertMessage
                                                        message={message.msg}
                                                        type={message.type === 'error' ? 'danger' : 'success'}
                                                        milliseconds={3000}
                                                        key={message.ran}
                                                    />
                                                </div>
                                            ) : (
                                                <span></span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-row align-items-end">
                                            <div className="col-8">
                                                <label>Minimum Age Requirement</label>
                                                <input
                                                    className="form-control"
                                                    id="min_booking_age"
                                                    name="min_booking_age"
                                                    maxLength={2}
                                                    type="text"
                                                    onChange={handleMinBookingAge}
                                                    value={minBookingAge || ''}
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <Button
                                                    className="right"
                                                    type="button"
                                                    variant="primary"
                                                    color="white"
                                                    disabled={loading}
                                                    onClick={handleButtonMinAgeRequirement}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="form-row align-items-end">
                                            <div className="col-auto">
                                                {message && message.item === 'min_age_requirement' && message.msg.length > 0 ? (
                                                    <div className={styles.alertBox}>
                                                        <AlertMessage
                                                            message={message.msg}
                                                            type={message.type === 'error' ? 'danger' : 'success'}
                                                            milliseconds={3000}
                                                            key={message.ran}
                                                        />
                                                    </div>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
