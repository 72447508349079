import React, {useState, useEffect} from "react";
import {ComplianceService} from "@common/units-api";
import {authManager} from "@common/authentication";
import {ComplianceItem, Permit, PropertyTax} from "@common/typing";
import {useToasts} from "react-toast-notifications";
import {ComplianceAppRender} from "./ComplianceAppRender";
import {ParseHelper} from "../../helpers/parse-helper";
import _ from "lodash";

let unitId: number = null;
let uuid: string = null;
let userEmail: string = null;

export const ComplianceApp: React.FunctionComponent<any> = (props) => {
    const [permits, setPermits] = useState<Array<ComplianceItem>>([]);
    const [propertyTaxes, setPropertyTaxes] = useState<Array<ComplianceItem>>([]);
    const [minBookingAge, setMinBookingAge] = useState(null);
    const [complianceNotes, setComplianceNotes] = useState("");
    const [permitTypes, setPermitTypes] = useState([]);
    const [propertyTaxTypes, setPropertyTaxTypes] = useState([]);
    const [disableBtnSavePermits, setDisableBtnSavePermits] = useState(true);
    const [loadingApp, setLoadingApp] = useState(false);
    const [loading, setLoading] = useState(false);

    const complianceService = new ComplianceService(authManager.getJwt());

    const {addToast} = useToasts();

    /** Enable save permits button, when the user makes a change */
    useEffect(() => {
        setDisableBtnSavePermits(false);
    }, [permits, propertyTaxes]);

    /** Load and set data */
    useEffect(() => {
        (async () => {
            try {
                setLoadingApp(true);
                await loadData();
            } catch (e) {
                console.error("error compliance app", e);
            } finally {
                setLoadingApp(false);
                setDisableBtnSavePermits(true);
            }
        })();
    }, []);

    /** Resize iframe for each render */
    useEffect(() => {
        resizeContent();
    });

    const resizeContent = () => {
        const height = document.getElementById("root").clientHeight;
        window.parent.postMessage({action: `${height}px`, type: "resize"}, "*");
    };

    //#region Fetches

    const loadData = async () => {
        if (_.isNil(unitId)) {
            const info = authManager.getInfoFromAdmin<{user: string; unitId: number}>();
            unitId = info.unitId;
            userEmail = info.user;
        }

        uuid = await complianceService.getUuidByLegacyUnitIdV2(unitId);

        const [permits, propertyTaxes, permitTypes, propertyTaxTypes, unit, unitBookingCompliance] = await Promise.all([
            fetchPermits(uuid),
            fetchPropertyTaxes(uuid),
            fetchPermitTypes(),
            fetchPropertyTaxTypes(),
            fetchUnit(),
            fetchUnitBookingCompliance(),
        ]);

        setPermitTypes(permitTypes);
        setPropertyTaxTypes(propertyTaxTypes);
        setPermits(permits);
        setPropertyTaxes(propertyTaxes);
        setMinBookingAge(_.get(unit, "attributes.min_booking_age", null));
        setComplianceNotes(_.get(unitBookingCompliance, "attributes.compliance_notes", ""));
    };

    const fetchPermitTypes = async () => {
        const permitTypes = await complianceService.getPermitTypes();
        if (_.isNil(permitTypes)) return [];

        return permitTypes.map((type, index) => {
            return {
                ...type,
                index: index,
            };
        });
    };

    const fetchPropertyTaxTypes = async () => {
        const types = await complianceService.getPropertyTaxTypes();
        if (_.isNil(types)) return [];

        return types.map((type, index) => {
            return {
                ...type,
                index: index,
            };
        });
    };

    const fetchPermits = async (uuid: string): Promise<Array<ComplianceItem>> => {
        const permits = await complianceService.getPermitsByUnitId(uuid);
        if (_.isNil(permits)) return [];

        return permits.map((permit, index) => {
            return {...ParseHelper.fromPermitToComplianceItem(permit), index};
        });
    };

    const fetchPropertyTaxes = async (uuid: string): Promise<Array<ComplianceItem>> => {
        const propertyTaxes = await complianceService.getPropertyTaxesByUnitId(uuid);
        if (_.isNil(propertyTaxes)) return [];

        return propertyTaxes.map((propertyTax, index) => {
            return {...ParseHelper.fromPropertyTaxToComplianceItem(propertyTax), index};
        });
    };

    const fetchUnit = async (): Promise<any> => {
        const unit = await complianceService.getUnitByUnitId(uuid);
        if (_.isNil(unit)) return null;

        return unit;
    };

    const fetchUnitBookingCompliance = async (): Promise<any> => {
        try {
            const response = await complianceService.getUnitBookingComplianceByUnitId(unitId);
            if (_.isNil(response?.attributes?.compliance_notes)) {
                return null;
            }

            return response;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    //#endregion

    const savePropertyTaxes = async (): Promise<boolean> => {
        const errors = [];

        try {
            setLoading(true);

            //save permits
            const deletedPropertyTaxes = propertyTaxes.filter(
                (propertyTax) => _.get(propertyTax, "id", null) !== null && propertyTax.deleted === true
            );
            const newPropertyTaxes = propertyTaxes.filter((propertyTax) => _.get(propertyTax, "id", null) === null && propertyTax.deleted !== true);
            const existingPropertyTaxes = propertyTaxes.filter(
                (propertyTax) => _.get(propertyTax, "id", null) !== null && propertyTax.deleted !== true
            );

            if (!_.isEmpty(deletedPropertyTaxes)) {
                for (const permit of deletedPropertyTaxes) {
                    try {
                        await complianceService.deletePropertyTax(ParseHelper.fromComplianceItemToPropertyTax(permit));
                    } catch (e) {
                        console.error("deletePropertyTax", e);
                        errors.push("error deleting PropertyTax");
                    }
                }
            }
            if (!_.isEmpty(newPropertyTaxes)) {
                try {
                    await complianceService.createPropertyTax(
                        newPropertyTaxes.map((propertyTax) => {
                            return ParseHelper.fromComplianceItemToPropertyTax(propertyTax);
                        }),
                        userEmail
                    );
                } catch (e) {
                    console.error("createPropertyTax", e);
                    errors.push("error creating new property taxes");
                }
            }
            if (!_.isEmpty(existingPropertyTaxes)) {
                for (const propertyTax of existingPropertyTaxes) {
                    try {
                        await complianceService.updatePropertyTax(ParseHelper.fromComplianceItemToPropertyTax(propertyTax), userEmail);
                    } catch (e) {
                        console.error("updatePropertyTax", e);
                        errors.push("error updating property Tax");
                    }
                }
            }
        } catch (error) {
            console.error("error saving", error);
            errors.push("error saving");
        } finally {
            await loadData();
            setLoading(false);

            return errors.length > 0 ? false : true;
        }
    };

    const savePermits = async (): Promise<boolean> => {
        const errors = [];

        try {
            setLoading(true);

            //save permits
            const deletedPermits = permits.filter((permit) => _.get(permit, "id", null) !== null && permit.deleted === true);
            const newPermits = permits.filter((permit) => _.get(permit, "id", null) === null && permit.deleted !== true);
            const existingPermits = permits.filter((permit) => _.get(permit, "id", null) !== null && permit.deleted !== true);

            if (!_.isEmpty(deletedPermits)) {
                for (const permit of deletedPermits) {
                    try {
                        await complianceService.deletePermit(ParseHelper.fromComplianceItemToPermit(permit));
                    } catch (e) {
                        console.error("deletePermit", e);
                        errors.push("error deleting permits");
                    }
                }
            }
            if (!_.isEmpty(newPermits)) {
                try {
                    await complianceService.createPermits(
                        newPermits.map((permit) => {
                            return ParseHelper.fromComplianceItemToPermit(permit);
                        }),
                        userEmail
                    );
                } catch (e) {
                    console.error("createPermits", e);
                    errors.push("error creating new permits");
                }
            }
            if (!_.isEmpty(existingPermits)) {
                for (const permit of existingPermits) {
                    try {
                        await complianceService.updatePermit(ParseHelper.fromComplianceItemToPermit(permit), userEmail);
                    } catch (e) {
                        console.error("updatePermit", e);
                        errors.push("error updating permits");
                    }
                }
            }

            /*
            errors.length > 0
                ? addToast('There was an error saving the data', {appearance: 'error'})
                : addToast('Data Succesfully Saved', {appearance: 'success'});
                */
        } catch (error) {
            console.error("error saving", error);
            errors.push("error saving");
            //addToast('There was an error saving the data', {appearance: 'error'});
        } finally {
            await loadData();
            setLoading(false);

            return errors.length > 0 ? false : true;
        }
    };

    const saveMinBookingAge = async () => {
        let isValid = true;
        try {
            const minBA = _.isEmpty(String(minBookingAge)) ? null : minBookingAge;
            await complianceService.updateMinBookingAge(uuid, minBA);
        } catch (error) {
            isValid = false;
            console.error("error saving", error);
        } finally {
            await loadData();
            setLoading(false);

            return isValid;
        }
    };

    const saveComplianceNotes = async () => {
        let isValid = true;
        try {
            setLoading(true);
            await complianceService.updateComplianceNotes(unitId, complianceNotes);
        } catch (error) {
            isValid = false;
            console.error("error saving", error);
        } finally {
            await loadData();
            setLoading(false);

            return isValid;
        }
    };

    const newPermit = () => {
        if (permits.filter((permit) => permit.deleted !== true).length >= 5) {
            return;
        }

        const clonePermits = _.cloneDeep(permits) as Array<ComplianceItem>;
        const permit: ComplianceItem = {attributes: {unit_id: uuid, display: false}, index: permits.length};
        clonePermits.push(permit);

        setPermits(clonePermits);
    };

    const newPropertyTax = () => {
        if (propertyTaxes.filter((propertyTax) => propertyTax.deleted !== true).length >= 3) {
            return;
        }

        const clonePropertyTaxes = _.cloneDeep(propertyTaxes) as Array<ComplianceItem>;
        const propertyTax: ComplianceItem = {attributes: {unit_id: uuid, display: false}, index: propertyTaxes.length};
        clonePropertyTaxes.push(propertyTax);

        setPropertyTaxes(clonePropertyTaxes);
    };

    const updatePermit = (permit: Permit) => {
        const clonePermits = _.cloneDeep(permits) as any;
        clonePermits[permit.index] = permit;

        setPermits(clonePermits);
    };

    const updatePropertyTax = (propertyTax: PropertyTax) => {
        const clonePropertyTaxes = _.cloneDeep(propertyTaxes) as any;
        clonePropertyTaxes[propertyTax.index] = propertyTax;

        setPropertyTaxes(clonePropertyTaxes);
    };

    const removePermit = (index: number) => {
        let clonePermits = _.cloneDeep(permits);
        clonePermits = clonePermits.map((permit) => {
            return permit.index === index ? {...permit, deleted: true} : permit;
        });

        setPermits(clonePermits);
    };

    const removePropertyTax = (index: number) => {
        let clonePropertyTaxes = _.cloneDeep(propertyTaxes);
        clonePropertyTaxes = clonePropertyTaxes.map((propertyTax) => {
            return propertyTax.index === index ? {...propertyTax, deleted: true} : propertyTax;
        });

        setPropertyTaxes(clonePropertyTaxes);
    };

    return (
        <ComplianceAppRender
            loadingApp={loadingApp}
            loading={loading}
            permits={permits}
            updatePermit={updatePermit}
            updatePropertyTax={updatePropertyTax}
            removePermit={removePermit}
            removePropertyTax={removePropertyTax}
            permitTypes={permitTypes}
            propertyTaxTypes={propertyTaxTypes}
            savePermits={savePermits}
            disableBtnSavePermits={disableBtnSavePermits}
            newPermit={newPermit}
            newPropertyTax={newPropertyTax}
            propertyTaxes={propertyTaxes}
            savePropertyTaxes={savePropertyTaxes}
            minBookingAge={minBookingAge}
            saveMinBookingAge={saveMinBookingAge}
            setMinBookingAge={setMinBookingAge}
            saveComplianceNotes={saveComplianceNotes}
            complianceNotes={complianceNotes}
            setComplianceNotes={setComplianceNotes}
        />
    );
};
