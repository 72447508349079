import React from "react";
import {ComplianceApp} from "./components/permit/ComplianceApp";
import {ToastProvider} from "react-toast-notifications";
import "./App.css";

function App() {
    return (
        <ToastProvider autoDismiss autoDismissTimeout={5000} placement="top-right">
            <div className="admin-app">
                <div className="container-fluid">
                    <div className="row">
                        <div className="container-custom">
                            <ComplianceApp />
                        </div>
                    </div>
                </div>
            </div>
        </ToastProvider>
    );
}

export default App;
